import React from "react";

import { SwitchLink } from "@components/ui/links";
import LottiePlayer from "@components/media/lottiePlayer";
import { ModuleWrapper } from "../moduleWrapper";

const Card = ({
  title,
  excerpt,
  icon,
  lottieFile,
  isLottie,
  cardLink,
  keyPoints,
}) => {
  return (
    <div className="bg-white p-6 rounded-lg overflow-hidden xl:px-10 xl:pt-16 flex flex-col justify-between relative">
      <div className="flex flex-col justify-between h-full">
        <h4 className="text-red text-3xl mb-one">{title}</h4>
        <h5 className="text-lg  leading-relaxed lg:text-2xl mb-one">
          {excerpt}
        </h5>
        {keyPoints && (
          <ul className="list-disc pl-5">
            {keyPoints.map((item) => (
              <li className="text-lg leading-relaxed text-gray-700 lg:text-2xl my-half lg:blockH4">
                {item}
              </li>
            ))}
          </ul>
        )}
        <SwitchLink
          {...cardLink[0]}
          className="btn-standard text-xs px-6 md:px-10 md:text-sm text-red md:w-max"
        />
      </div>
      <div className="self-end">
        {!isLottie && (
          <div
            dangerouslySetInnerHTML={{ __html: icon }}
            className="w-9 lg:w-20 ml-auto svg-wrapper absolute top-6 right-6"
          />
        )}
        {isLottie && lottieFile?.asset && (
          <LottiePlayer
            url={lottieFile.asset.url}
            className="w-48 h-48 ml-auto lg:w-80 lg:h-80"
          />
        )}
      </div>
    </div>
  );
};

const IconCardGrid = ({ config, cards }) => {
  return (
    <ModuleWrapper {...config} className="px-gutter " revealAnimation>
      <div className="grid grid-cols-1 gap-10 md:grid-cols-2 ">
        {cards.map((card) => (
          <Card {...card} />
        ))}
      </div>
    </ModuleWrapper>
  );
};

export default IconCardGrid;
